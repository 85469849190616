<template>
  <div>
    <div class="page page-job-detail" v-if="!loading">
      <div class="scroll-content">
        <!-- 描述 -->
        <div class="desc">
          <div class="desc-1">
            <div class="company-title">
              {{ detail.jobName || "" }}
            </div>

            <div style="display: flex; align-items: center">
              <div style="margin-right: 0.15rem">
                <button class="collect-button" @click="goWechat">
                  <span>收藏</span>
                </button>
                <button class="share-button" @click="goWechat">投递</button>
              </div>
            </div>
          </div>
          <div class="desc-2">
            <div class="price">
              <span class="now-price">
                {{ getSalaryText() }}
              </span>
            </div>

            <span class="time">
              更新时间：{{
                detail.updateTime ? detail.updateTime.split(" ")[0] : ""
              }}
            </span>
          </div>
          <div class="desc-3">
            <div class="custom-tag">
              {{ detail.socialRecruitment | formatSocial }}
            </div>
            <div class="custom-tag">
              {{ detail.workType | formatWorkType }}
            </div>
          </div>
        </div>

        <!-- 入职返佣 -->
        <div
          class="card-wrapper card-welfare"
          v-if="detail.entryRebate || detail.rebateRule"
        >
          <div class="title">
            <span>入职返佣</span>
          </div>
          <div class="entry-rebate" v-if="detail.entryRebate">
            <span>{{ detail.entryRebate }}元</span>
          </div>
          <div class="rebate-rule" v-if="detail.rebateRule">
            <span>{{ detail.rebateRule || "" }}</span>
          </div>
        </div>

        <!-- 企业福利 -->
        <div class="card-wrapper card-desc" v-if="detail.jobWelfare">
          <div class="title">
            <span>职位福利</span>
          </div>
          <div class="tags-wrapper">
            <div
              class="custom-tag"
              border="1px solid #ddd;"
              v-for="item in detail.jobWelfare.split(',')"
              :key="item"
            >
              <span style="color: #999">{{ item }}</span>
            </div>
          </div>
        </div>

        <!-- 岗位描述 -->
        <div class="card-wrapper card-desc">
          <div class="title">
            <span>岗位描述</span>
          </div>
          <div class="tags-wrapper">
            <div style="margin-bottom: 0.1rem" v-if="detail.workDescription">
              <div
                class="custom-tag"
                border="1px solid #ddd;"
                v-for="item in detail.workDescription.split(',')"
                :key="item"
              >
                <span style="color: #999">{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="desc-wrapper">
            <span
              style="word-break: break-all"
              v-html="formatBr(detail.jobIntroduction)"
            >
            </span>
          </div>
        </div>

        <!-- 录用条件 -->
        <div class="card-wrapper card-desc">
          <div class="title">
            <span>录用条件</span>
          </div>
          <div class="tags-wrapper">
            <div class="custom-tag" border="1px solid #ddd;">
              <span style="color: #999">{{ detail.ageRequirement }}岁</span>
            </div>
            <div
              class="custom-tag"
              border="1px solid #ddd;"
              v-for="item in detail.reducingConditions"
              :key="item"
            >
              <span style="color: #999">{{ item }}</span>
            </div>
            <div
              style="margin-bottom: 0.1rem"
              v-if="detail.interdivRequirements"
            >
              <div
                class="custom-tag"
                border="1px solid #ddd;"
                v-for="item in detail.interdivRequirements.split(',')"
                :key="item"
              >
                <span style="color: #999">{{ item }}</span>
              </div>
            </div>
            <div
              style="margin-bottom: 0.1rem"
              v-if="detail.majorNames && detail.majorNames.length"
            >
              <div
                class="custom-tag"
                border="1px solid #ddd;"
                v-for="item in detail.majorNames"
                :key="item"
              >
                <span style="color: #999">{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="desc-wrapper">
            <span>
              {{ detail.otherRequirements || "" }}
            </span>
          </div>
        </div>

        <!-- 公司信息 -->
        <div class="card-wrapper card-company">
          <div class="title">
            <span>公司信息</span>
          </div>
          <div class="info">
            <div class="avatar">
              <img
                :src="detail.cmpyInfoVo ? detail.cmpyInfoVo.imgUrl : defalutImg"
              />
            </div>
            <div class="text">
              <span class="name">{{ detail.cmpyName }}</span>
              <span class="ellipsis">
                {{
                  detail.cmpyInfoVo
                    ? detail.cmpyInfoVo.busiLicsBusiness || ""
                    : ""
                }}
              </span>
              <span>
                在招职位{{
                  detail.cmpyInfoVo ? detail.cmpyInfoVo.jobnum || 0 : 0
                }}个
              </span>
            </div>
          </div>
        </div>

        <div class="card-wrapper card-company-place">
          <div class="title">
            <span>工作地址</span>
          </div>
          <div class="place">
            {{ detail.proName }}
            {{ detail.cityName }}
            {{ detail.countryName }}
            {{ detail.jobAddress }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkErrCode } from "@/utils";
import { getSalaryText } from "@/utils";
import { getxcxJobInfo } from "@/api/recruit/jobInfo";

export default {
  data() {
    return {
      loading: true,
      detail: {
        cmpyInfoVo: {},
      }, //job详情
      defalutImg: require("@/assets/default2.jpg"),
    };
  },

  computed: {},
  filters: {
    formatSocial(value) {
      const rules = {
        1: "社招",
        2: "校招",
      };
      return rules[value] || value;
    },
    formatWorkType(value) {
      const rules = {
        1: "全职",
        2: "兼职",
        3: "日结",
        4: "实习",
        5: "短期工",
      };
      return rules[value] || value;
    },
  },
  watch: {},
  created() {
    // 20231124 为人力资源兼容title显示 - start
    const { originPath } = this.$route.query;
    if (originPath === "jobR") document.title = "郑州航空港人力资源综合服务中心";
    this.getDetail();
  },
  methods: {
    getSalaryText() {
      return getSalaryText({
        ...this.detail,
        jobMaxSalary: this.detail.jobMaxSalary * 100,
        jobMinSalary: this.detail.jobMinSalary * 100,
      });
    },
    getDetail() {
      this.loading = true;
      const params = {
        apiName: "/recruit/job-info/getxcxJobInfo",
        paramMap: {
          jobCode: this.$route.query.jobCode,
        },
        requestType: 1,
      };
      getxcxJobInfo(params).then((res) => {
        if (checkErrCode(res)) {
          this.detail = res.data;
          this.loading = false;
        }
      });
    },

    goWechat() {
      //https://xgp-6gf8qcf2022b53fd-1308484041.tcloudbaseapp.com/jump-mp.html?sign=c1756c4f9fa6f59186b8379badf0b02b&t=1695710173
      window.location.href = `https://xgp-6gf8qcf2022b53fd-1308484041.tcloudbaseapp.com/jump-mp-xgg1.html?id=${
        this.$route.query.jobCode
      }&t=${new Date().getTime()}`;
    },

    formatBr(text) {
      if (text) {
        return text.replace(/\n/g, "<br/>");
      } else {
        return text;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  position: relative;
}
.scroll-wrapper {
  height: 100%;
}
.scroll-content {
  background: #f9f9f9;
}
.map-wrapper {
  height: 3.6rem;
}
.fav-tag {
  line-height: 0.4rem;
  height: 0.4rem;
  width: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.24rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  &.hasFav {
    color: #666;
    background: #fff;
    border: 1px solid #666;
  }
  &.fav {
    color: #fff;
    background: #3c37d2;
  }
}
.desc {
  background: #fff;
  margin-bottom: 0.2rem;
  padding: 0.3rem;
  position: relative;
  z-index: 2;
  .desc-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
    .company-title {
      flex: 1;
      font-size: 0.4rem;
      color: #000;
      font-weight: bold;
    }

    .collect-button {
      display: inline-flex;
      font-size: 0.22rem;
      height: 0.5rem;
      line-height: 0.5rem;
      padding: 0 0.2rem;
      color: #3c37d2;
      border: 1px solid #3c37d2;
      margin-right: 0.2rem;
      border-radius: 0.25rem;
    }

    .share-button {
      display: inline-flex;
      font-size: 0.22rem;
      height: 0.5rem;
      line-height: 0.5rem;
      padding: 0 0.2rem;
      background: #3c37d2;
      color: #fff;
      border-radius: 0.25rem;
    }
  }
  .desc-2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.25rem;

    .time {
      font-size: 0.2rem;
      color: #999;
      flex: 0 0 3rem;
      width: 3rem;
      text-align: right;
    }

    .now-price {
      font-size: 0.4rem;
      line-height: 0.56rem;
      color: #ff4451;
    }
  }
  .desc-3 {
    display: flex;
  }
}
.custom-tag {
  margin-right: 0.2rem;
  padding: 0 0.2rem 0 0.2rem;
  height: 0.34rem;
  background: #ffffff;
  border-radius: 0.04rem;
  border: 0.01rem solid #979797;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.24rem;
  color: #999999;
  margin-bottom: 0.16rem;
}
.card-wrapper {
  background: #fff;
  padding: 0.3rem;
  margin-bottom: 0.2rem;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  .title {
    font-size: 0.3rem;
    font-weight: bold;
    margin-bottom: 0.3rem;
    line-height: 0.36rem;
  }
}
.desc-wrapper {
  color: #666;
  font-size: 0.24rem;
  line-height: 0.4rem;
}
.card-desc {
  .tags-wrapper {
    margin-bottom: 0.3rem;
  }
}
.entry-rebate {
  font-size: 0.4rem;
  font-weight: 500;
  color: #ff4451;
  line-height: 0.4rem;
}
.rebate-rule {
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.33rem;
  margin-top: 0.15rem;
}
.job-tag {
  display: inline-block;
  margin-right: 0.18rem;
  font-size: 0.24rem;
  color: #999;
  margin-bottom: 0.1rem;
}
.card-company {
  background: #fff;
  padding: 0.4rem 0.3rem;
  .info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.2rem;
    .avatar {
      width: 0.8rem;
      height: 0.8rem;
      background: #ddd;
      flex: 0 0 0.8rem;
      border-radius: 0.08rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 0.2rem;
      color: #999;
      font-size: 0.22rem;
      line-height: 0.4rem;
      overflow: hidden;
      .name {
        font-size: 0.28rem;
        line-height: 0.34rem;
        margin-bottom: 0.08rem;
        color: #666;
      }
      .ellipsis {
        width: 100%;
      }
    }
  }
}
.card-analyse {
  .tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;
    font-size: 0.24rem;
  }
  .btn {
    /deep/ .tui-btn {
      font-size: 0.2rem !important;
      background: #3c37d2 !important;
      color: #fff;
    }
  }
}
.share-popup {
  width: 85vw;
  border-radius: 0.15rem;
  background: #fff;
  padding: 0.3rem;
  .share-model-img {
    width: 100%;
    height: auto;
  }
  .share-main-title {
    font-size: 0.35rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.4rem;
  }
  .share-title {
    font-size: 0.25rem;
  }
}
.poster-popup {
  .poster-img {
    width: 6rem;
    height: auto;
  }
  .poster-pop-btn {
    margin-top: 0.2rem;
  }
}

.card-company-place {
  .place {
    color: rgb(153, 153, 153);
  }
}
</style>
<style lang="scss">
.page-job-detail {
  .u-mode-center-box {
    background-color: transparent !important;
  }
}
</style>
